import React from "react"
import theme from "theme"
import { Theme, Text, Box, Icon, Link, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
import { MdLocationOn, MdPhone, MdMailOutline } from "react-icons/md"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"contacts"} />
      <Helmet>
        <title>Івент агенція RUSH</title>
        <meta
          name={"description"}
          content={"Перетворюємо ваші моменти на спогади на все життя!"}
        />
        <meta property={"og:title"} content={"Івент агенція RUSH"} />
        <meta
          property={"og:description"}
          content={"Перетворюємо ваші моменти на спогади на все життя!"}
        />
        <meta
          property={"og:image"}
          content={"https://top.movixentop.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://top.movixentop.com/img/birthday.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://top.movixentop.com/img/birthday.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://top.movixentop.com/img/birthday.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://top.movixentop.com/img/birthday.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://top.movixentop.com/img/birthday.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://top.movixentop.com/img/birthday.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="80px 0 300px 0"
        background="--color-darkL1"
        color="--lightD2"
        quarkly-title="Contacts-8"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          align-items="flex-start"
          lg-flex-direction="column"
        />
        <Box
          min-width="100px"
          min-height="100px"
          width="40%"
          lg-width="100%"
          display="flex"
          flex-direction="column"
          justify-content="center"
          lg-margin="0px 0 30px 0px"
          align-items="flex-start"
          sm-margin="0px 0px 0 0px"
          lg-padding="0px 180px 0px 0px"
          sm-padding="0px 0 0px 0px"
          md-padding="0px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 20px 0px"
            font="normal 600 38px/1.2 --fontFamily-sansHelvetica"
            color="--light"
          >
            Готові створити незабутні спогади?
          </Text>
          <Text
            margin="0px 0px 50px 0px"
            font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
            color="--lightD2"
            lg-margin="0px 0px 20px 0px"
            md-width="100%"
          >
            Зв'яжіться з нами вже сьогодні, і давайте почнемо планувати подію
            вашої мрії!
          </Text>
        </Box>
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="16px 24px"
          margin="0px 0 0 10%"
          lg-grid-template-columns="repeat(2, 1fr)"
          lg-grid-gap="36px 34px"
          md-grid-template-columns="1fr"
          flex-direction="column"
          width="50%"
          lg-margin="0px 0 0 0"
          lg-width="100%"
        >
          <Box
            min-width="10px"
            min-height="10px"
            display="flex"
            flex-direction="row"
            border-color="--color-lightD2"
            border-radius="4px"
            align-items="center"
            margin="0px 0px 30px 0px"
            lg-margin="0px 0px 10px 0px"
            sm-margin="0px 0px 0 0px"
          >
            <Icon
              category="md"
              icon={MdLocationOn}
              size="54px"
              margin="0px 20px 0 0px"
              color="--darkL1"
              background="--color-light"
              padding="12px 12px 12px 12px"
              border-radius="50%"
            />
            <Box
              min-width="10px"
              min-height="10px"
              display="flex"
              flex-direction="column"
              align-items="flex-start"
              md-justify-content="center"
            >
              <Text
                color="--light"
                text-decoration-line="initial"
                font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
                display="block"
                margin="0px 0px 5px 0px"
                sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
              >
                вул. Січових Стрільців 4а, м. Дніпро, 49000
              </Text>
            </Box>
          </Box>
          <Box
            min-width="10px"
            min-height="10px"
            display="flex"
            flex-direction="row"
            border-color="--color-lightD2"
            border-radius="4px"
            align-items="center"
            margin="0px 0px 30px 0px"
            lg-margin="0px 0px 10px 0px"
            sm-margin="0px 0px 0 0px"
          >
            <Icon
              category="md"
              icon={MdPhone}
              size="54px"
              margin="0px 20px 0 0px"
              color="--darkL1"
              background="--color-light"
              padding="12px 12px 12px 12px"
              border-radius="50%"
            />
            <Box
              min-width="10px"
              min-height="10px"
              display="flex"
              flex-direction="column"
              align-items="flex-start"
              md-justify-content="center"
            >
              <Link
                href="tel:098 188 00 48"
                color="--light"
                text-decoration-line="initial"
                font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
                display="block"
                margin="0px 0px 5px 0px"
                sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
              >
                098 188 00 48
              </Link>
            </Box>
          </Box>
          <Box
            min-width="10px"
            min-height="10px"
            display="flex"
            flex-direction="row"
            border-color="--color-lightD2"
            border-radius="4px"
            align-items="center"
          >
            <Icon
              category="md"
              icon={MdMailOutline}
              size="54px"
              margin="0px 20px 0 0px"
              color="--darkL1"
              background="--color-light"
              padding="12px 12px 12px 12px"
              border-radius="50%"
            />
            <Box
              min-width="10px"
              min-height="10px"
              display="flex"
              flex-direction="column"
              align-items="flex-start"
              md-justify-content="center"
            >
              <Link
                href="mailto:about@top.movixentop.com"
                color="--light"
                text-decoration-line="initial"
                font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
                display="block"
                margin="0px 0px 5px 0px"
                sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
              >
                about@top.movixentop.com
              </Link>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
